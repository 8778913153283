<template>
  <div class="article safe-area">
    <el-page-header class="margin-top-20" @back="$router.go(-1)" content="详情"></el-page-header>
    <div class="column margin-top-20">
      <div class="font-size-20 bold">{{noticeDetail.notice_title}}</div>
      <div class="color-sub border-bottom padding-tb-10">{{noticeDetail.pub_time}}</div>
      <div class="article-detail line-height-large margin-top-20" v-html="noticeDetail.notice_content"></div>
    </div>
  </div>
</template>

<script>
export default {
  components: { },
  name: 'Home',
  data() {
    return {
      noticeId:this.$route.query.id,
      noticeDetail:{}
    }
  },
  computed:{

  },
  methods:{
    //获取公告列表
    getDetail(){
      let params= {
        ...this.$store.state.basicParams,
        notice_id:this.noticeId,
      }
      this.$instance.get('/main/get_notice_info',{params})
      .then(res=>{
        if(res.data.code==0){
          this.noticeDetail=res.data.data
        }
      })
      .catch(()=> {}); 
    },
  },
  created(){
    this.noticeId&&this.getDetail()
  },
  mounted(){
    
  },
  beforeDestroy() {
 
  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.article-detail{
  img{
    max-width: 100%;
    display: block;
    margin: 0 auto;
  }
}
</style>
<style>
  @import '../../assets/css/product-list-item.scss';
</style>
